body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.navbar {
  background-color: #4DD1D1;
  padding: 1rem;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-icon {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
}

.close-icon {
  display: none;
  width: 1.5rem;
  height: 1.5rem;
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1002; /* Ensure it stays above the menu */
  cursor: pointer;
}

.close-icon:before,
.close-icon:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px; /* Adjust thickness here */
  background-color: white;
  top: 50%;
  left: 0;
  transform-origin: center;
}

.close-icon:before {
  transform: rotate(45deg);
  height: 1px; /* Adjust thickness of the cross */
}

.close-icon:after {
  transform: rotate(-45deg);
  height: 1px; /* Adjust thickness of the cross */
}

.nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.nav-links li {
  margin-left: 2rem;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.107em;
  font-family: 'Raleway', Arial, sans-serif;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .navbar-container {
    justify-content: left;
  }

  .nav-links {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgb(22, 22, 22);
    flex-direction: column;
    align-items: flex-start;
    padding-top: 3rem;
    padding-left: 1rem; /* Add padding for links */
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000; /* Ensure it covers the navbar */
  }

  .nav-links.open {
    transform: translateX(0);
  }

  .horizontal-line {
    width: 90%;
    height: 1px;
    background-color: rgba(76, 76, 76, 0.5);
  }

  .nav-links li {
    margin: 1.1rem;
  }

  .close-icon {
    display: block;
  }

  .close-icon:before,
  .close-icon:after {
    height: 1px; /* Adjust thickness of the cross */
  }
}
