/* Contact Section */
.contact-section {
    padding: 20px;
}

.contact-body {
    display: flex;
    justify-content: space-between;
}

.body1,
.body2 {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    text-align: left;
    position: relative;
    transition: transform 0.5s ease-in-out;
}

.body2 {
    align-content: center;
}

.body1 h3,
.body1 h4 {
    font-size: 24px;
    margin-bottom: 10px;
    font-family: 'Raleway', arial, sans-serif;
    letter-spacing: 0.107em;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1.25;
}

.section {
    margin-bottom: 40px;
}

.body1 p {
    font-size: 16px;
    margin: 0px;
    color: rgb(94, 94, 94);
    font-weight: 400;
    line-height: 1.5;
    font-family: 'Raleway', arial, sans-serif;
}

.body1 a {
    color: rgb(49, 127, 153);
    text-decoration: none;
}

.body1 a:hover {
    text-decoration: underline;
}

.contact-button {
    /* margin-top: 20px; */
    padding: 10px 20px;
    min-height: 50px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Raleway', arial, sans-serif;
    color: #333;
    background-color: #fff;
    border: 1px solid #333;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-button:hover {
    background-color: #4DB0D1;
}

.contact-image {
    width: 90%;
}

.hours-toggle {
    cursor: pointer;
    color: #00bfff;
    display: inline-flex;
    align-items: center;
    position: relative;
}

.arrow {
    width: 15px;
    height: auto;
    margin-left: 10px;
    cursor: pointer;
}

.hours-details {
    margin-top: 10px;
}

.today-hours{
    color: rgb(49, 127, 153);
}

.today-hours:hover {
    color: #195569;
}

.hours-row {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: 'Raleway', arial, sans-serif;
}

.hours-row span:first-child {
    flex-basis: 15%;
    font-size: 16px;
    color: rgb(94, 94, 94);
    line-height: 1.5;
}

.hours-row span:last-child {
    flex-basis: 85%;
    font-size: 16px;
    color: rgb(94, 94, 94);
    line-height: 1.5;
}

.current-day span {
    font-weight: bold;
    color: black;
}

.form-container {
    padding: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.contact-form h3 {
    font-size: 16px;
    color: rgb(27, 27, 27);
    margin-bottom: 24px;
    line-height: 1.25;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.107em;
    font-family: Raleway, arial, sans-serif;
}

.contact-form label {
    margin-top: 10px;
    font-size: 16px;
    color: rgb(71, 71, 71);
    font-weight: 400;
    font-family: Raleway, arial, sans-serif;
    letter-spacing: normal;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
}

textarea {
    min-height: 80px;
}

.contact-form .form-actions {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.contact-form .form-actions button {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Raleway', arial, sans-serif;
    color: #333;
    background-color: #fff;
    border: 1px solid #333;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-form .form-actions button:hover {
    background-color: #4DB0D1;
}

@media (max-width: 768px) {
    .contact-body {
        flex-direction: column-reverse;
    }

    .form-container {
        padding: 0px;
    }

    .contact-image {
        width: 100%;
    }
}