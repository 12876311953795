.parent-container {
  padding-left: 10%;
  padding-right: 10%;
  flex: 1;
}

/* Flexbox container */
.main-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media (max-width: 1200px) {
  .parent-container {
    padding-left: 8%;
    padding-right: 8%;
  }
}

@media (max-width: 992px) {
  .parent-container {
    padding-left: 6%;
    padding-right: 6%;
  }
}

@media (max-width: 768px) {
  .parent-container {
    padding-left: 4%;
    padding-right: 4%;
  }
}

@media (max-width: 576px) {
  .parent-container {
    padding-left: 2%;
    padding-right: 2%;
  }
}