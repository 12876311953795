/* General styling */
.home-container {
  font-family: Arial, sans-serif;
  text-align: center;
}

/* Hero Section */
.hero-section {
  padding: 30px 0;
  display: flex;
  justify-content: center;
}

.title {
  font-family: 'Sacramento', cursive;
  font-size: 3rem;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

/* About Section */
.about-section {
  padding: 50px 0;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  width: 100%;
  gap: 20px; /* Add space between the profile image and text containers */
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.centered-image {
  max-width: 100%;
  height: auto;
  border-radius: 0px;
  border-left-width: 8px;
  border-right-width: 8px;
  border-top-width: 8px;
  border-bottom-width: 8px;
  border-style: solid;
  border-color: #4DB0D1;
}

.left-aligned-text {
  text-align: left;
  font-size: 16px;
  color: rgb(94, 94, 94);
  line-height: 1.5;
  font-weight: 400;
}

h4 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.107em;
}


@media (max-width: 768px) {
  .row {
    flex-direction: column;
    align-items: flex-start;
  }

  .row:nth-child(2) {
    flex-direction: column-reverse;
  }

  .about-image {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .about-text {
    max-width: 100%;
  }
}